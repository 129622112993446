import React from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';

const DataChart = ({
  series,
  text,
  months,
  goals,
  data,
  monthsLastYear,
}) => {
  // Verificar si es un gráfico de barras horizontales (para grupos de artículos o clientes)
  const isHorizontalBarChart = text.includes('Grupo') || text.includes('Clientes');

  // Preparar los datos para Highcharts
  const highchartsSeries = series.map((item) => ({
    name: item.name,
    color: item.color,
    data: item.data,
    type: isHorizontalBarChart ? 'bar' : 'column',
  }));

  // Agregar la serie de metas si existe
  if (goals && goals.length > 0) {
    highchartsSeries.push({
      name: 'Meta',
      type: 'line',
      data: goals,
      color: '#FEB019',
      dashStyle: 'dash',
      marker: {
        enabled: false,
      },
    });
  }

  // Formatear los nombres largos para que se muestren correctamente
  const formattedCategories = [];
  if (months && Array.isArray(months)) {
    for (let i = 0; i < months.length; i += 1) {
      const name = months[i];
      if (name && typeof name === 'string' && name.length > 20) {
        formattedCategories.push(`${name.slice(0, 20)}...`);
      } else {
        formattedCategories.push(name);
      }
    }
  }

  const chartOptions = {
    chart: {
      type: isHorizontalBarChart ? 'bar' : 'column',
      height: isHorizontalBarChart ? (formattedCategories.length * 40 + 100) : 400,
      width: null,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: formattedCategories,
      labels: {
        style: {
          fontSize: '11px',
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        formatter() {
          return `$${Highcharts.numberFormat(this.value || 0, 0, ',', '.')}`;
        },
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      shared: true,
      formatter() {
        // Para gráficos de grupos de artículos o clientes
        if (isHorizontalBarChart) {
          if (!this.x) {
            return 'No hay datos disponibles';
          }

          const currentItem = data?.find((item) => {
            const name = item.articleGroup?.name || item.client?.name;
            const xValue = this.x || '';
            const maxLength = Math.min(20, xValue.length);
            const startsWith = name && xValue && name.startsWith(xValue.substring(0, maxLength));
            return name === xValue || startsWith;
          });

          if (!currentItem) {
            return `<b>${this.x}</b><br/>$${Highcharts.numberFormat(this.y || 0, 0, ',', '.')}`;
          }

          let tooltip = `<b>${currentItem.articleGroup?.name || currentItem.client?.name}</b><br/>`;
          tooltip += `Total ventas: $${Highcharts.numberFormat(currentItem.totalSell || 0, 0, ',', '.')}`;

          return tooltip;
        }

        // Para gráficos de meses
        if (!data || !this.x) {
          return `<b>${this.x || 'Sin datos'}</b><br/>$${Highcharts.numberFormat(this.y || 0, 0, ',', '.')}`;
        }

        const currentMonthData = data.find((item) => item.month === this.x);
        if (!currentMonthData) {
          return `<b>${this.x}</b><br/>No data available`;
        }

        const totalPedidos = currentMonthData?.totalInvoices || 0;
        const totalVentas = currentMonthData?.totalSell || 0;
        const ticketMedio = totalPedidos > 0 ? totalVentas / totalPedidos : 0;

        let tooltip = `<b>${this.x}</b><br/>`;
        tooltip += `Total ventas: $${Highcharts.numberFormat(totalVentas, 0, ',', '.')}<br/>`;
        tooltip += `Total facturas: ${totalPedidos}<br/>`;
        tooltip += `Ticket medio: $${Highcharts.numberFormat(ticketMedio, 0, ',', '.')}<br/>`;
        tooltip += `Total clientes: ${currentMonthData?.totalClientDirections || 0}<br/>`;

        const d = data.find((item) => item.month === this.x);

        if (d && d.lastYearMonth) {
          const ticketMedioLastYear = d.lastYearMonth.totalInvoices > 0
            ? d.lastYearMonth.totalSell / d.lastYearMonth.totalInvoices
            : 0;

          tooltip += '<b>Año anterior</b><br/>';
          tooltip += `Total ventas: $${Highcharts.numberFormat(d.lastYearMonth.totalSell, 0, ',', '.')}<br/>`;
          tooltip += `Total facturas: ${d.lastYearMonth.totalInvoices}<br/>`;
          tooltip += `Ticket medio: $${Highcharts.numberFormat(ticketMedioLastYear, 0, ',', '.')}<br/>`;
          tooltip += `Total clientes: ${d.lastYearMonth?.totalClientDirections || 0}<br/>`;
        }

        return tooltip;
      },
    },
    plotOptions: {
      column: {
        borderRadius: 3,
        pointPadding: series.length > 1 ? 0.05 : 0.2,
        groupPadding: series.length > 1 ? 0.1 : 0.2,
        borderWidth: 0,
      },
      bar: {
        borderRadius: 3,
        pointPadding: 0.1,
        groupPadding: 0.1,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter() {
            return `$${Highcharts.numberFormat(this.y || 0, 0, ',', '.')}`;
          },
        },
      },
    },
    series: highchartsSeries,
    credits: {
      enabled: false,
    },
  };

  // Si es un gráfico horizontal, usamos las categorías originales para el tooltip
  if (isHorizontalBarChart && monthsLastYear && monthsLastYear.length > 0) {
    chartOptions.xAxis.categories = formattedCategories;
  }

  return (
    <Card>
      <CardHeader title={text} />
      <Divider />
      <CardContent>
        <div style={{ padding: '16px', width: '100%' }}>
          <div style={{
            display: 'flex', alignItems: 'center', marginBottom: '16px', flexWrap: 'wrap', gap: '16px',
          }}>
            {series.map((serie, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: serie.color,
                  marginRight: '8px',
                }} />
                <span style={{ fontSize: '14px', color: '#666', marginRight: '16px' }}>
                  {serie.name}
                </span>
              </div>
            ))}
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
          />
        </div>
      </CardContent>
    </Card>
  );
};

DataChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.number).isRequired,
  })),
  text: PropTypes.string.isRequired,
  months: PropTypes.array,
  goals: PropTypes.array,
  data: PropTypes.array,
  monthsLastYear: PropTypes.array,
};

DataChart.defaultProps = {
  months: [],
  goals: null,
  data: null,
  monthsLastYear: [],
};

export default DataChart;
