import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { withRouter, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SELLER } from '../../../constants/userRoles';

import useStyles from './styles';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import SellerContent from './SellerContent';
import getObjetives from '../../../requests/api/objetive';
import { getAverageMargin, getSellerDetailViewData } from '../../../requests/api/seller';

function DashboardView() {
  const classes = useStyles();
  const user = useSelector((state) => state.account.user);
  const { currentSeller } = useSelector((state) => state.seller || { currentSeller: null });
  const { sellerId } = useParams();
  const [objetive, setObjetive] = useState({});
  const [chartData, setChartData] = useState({});
  const [averageMargin, setAverageMargin] = useState([]);
  const [averageMarginLastYear, setAverageMarginLastYear] = useState([]);
  const [goals, setGoals] = useState([]);

  // Función para obtener el ID del vendedor correcto según el contexto
  const getEffectiveSellerId = () => {
    if (user.role === SELLER) {
      return user.seller.id;
    }
    return sellerId || currentSeller;
  };

  const getSeller = async () => {
    const effectiveSellerId = getEffectiveSellerId();
    if (!effectiveSellerId) return;
    const data = await getSellerDetailViewData(effectiveSellerId);
    setChartData(data.seller);
    setGoals(data.seller.thisYearObjectives);
  };

  const getMetrics = async () => {
    const effectiveSellerId = getEffectiveSellerId();
    if (!effectiveSellerId) return;
    const data = await getObjetives(effectiveSellerId);
    setObjetive(data.objective);
  };

  const getAverageMarginData = async () => {
    const effectiveSellerId = getEffectiveSellerId();
    if (!effectiveSellerId) return;

    const data = await getAverageMargin(effectiveSellerId, 2025);
    setAverageMargin(data.averageMargin || []);
  };

  const getAverageMarginDataLastYear = async () => {
    const effectiveSellerId = getEffectiveSellerId();
    if (!effectiveSellerId) return;

    const data = await getAverageMargin(effectiveSellerId, 2024);
    setAverageMarginLastYear(data.averageMargin || []);
  };

  useEffect(() => {
    const effectiveSellerId = getEffectiveSellerId();
    if (effectiveSellerId) {
      getMetrics();
      getSeller();
      getAverageMarginData();
      getAverageMarginDataLastYear();
    }
  }, [sellerId, currentSeller]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Header />
        <SellerContent
         objetive={objetive}
         chartData={chartData}
         goals={goals}
         averageMarginData={averageMargin}
         averageMarginDataLastYear={averageMarginLastYear} />
      </Container>
    </Page>
  );
}

export default withRouter(DashboardView);
