/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UploadObjectivesModal from './UploadObjectiveModal';
import pesoChileno from '../../../utils/moneyFormat';
import { formatMonthAndYearMoment } from '../../../utils/date';
import { updateCurrentSeller } from '../../../store/actions/sellerActions';

import useStyles from './styles';

function Results({
  className,
  sellers,
  page,
  setPage,
  limit,
  setLimit,
  filters,
  setFilters,
  setSendQuery,
  currentMonth,
  totalSellers,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentSellers, setCurrentSellers] = useState(sellers);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCurrentSellers(sellers);
  }, [sellers]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleFilterChange = (value, name) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleUploadObjective = () => {
    setOpen(true);
  };

  const handleCloseUploadObjective = () => {
    setSendQuery((prev) => !prev);
    setOpen(false);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        p={2}
        spacing={1}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-start"
          xs={12}
          sm={7}
          p={1}
          spacing={2}
        >
          <Grid item>
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleFilterChange(e.target.value, 'search')}
              placeholder="Código o Nombre vendedor"
              value={filters.search}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              className={classes.searchButton}
              onClick={() => setSendQuery((prev) => !prev)}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          sm={5}
          p={1}
          spacing={2}
          className={classes.buttonContainer}
        >
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleUploadObjective()}
            >
              Cargar objetivos
            </Button>
            <UploadObjectivesModal
              open={open}
              handleClose={() => handleCloseUploadObjective()}
            />
          </Grid>
        </Grid>
      </Grid>

      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Objetivo actual</TableCell>
                <TableCell>% logro</TableCell>
                <TableCell align="right">Ver más</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentSellers.map((seller) => {
                const sellerMonthObjective = seller.objectives.find(
                  (obj) => formatMonthAndYearMoment(obj.month)
                    === formatMonthAndYearMoment(currentMonth),
                )?.amount || 0;
                const sellerInvoiceProducts = seller.invoiceProducts;
                const sellerTotalInvoice = sellerInvoiceProducts.length > 0
                  ? sellerInvoiceProducts.reduce(
                    (acc, invoceProduct) => acc + (invoceProduct.netValue || 0),
                    0,
                  )
                  : 0;
                const achievement = sellerMonthObjective
                  ? (sellerTotalInvoice / sellerMonthObjective) * 100
                  : 0;
                const percentageAchievement = achievement
                  ? achievement.toFixed(2)
                  : 0;
                return (
                  <TableRow hover key={seller.id}>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {seller.sapId}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {seller.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {seller.active ? 'Activo' : 'Inactivo'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {pesoChileno(sellerMonthObjective)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {`${percentageAchievement}%`}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/vendedor/${seller.id}/dashboard`}
                        onClick={() => dispatch(updateCurrentSeller(seller.id))}
                        size="large"
                      >
                        <SvgIcon fontSize="small">
                          <MoreHorizIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalSellers}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  sellers: PropTypes.array,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setSendQuery: PropTypes.func.isRequired,
  currentMonth: PropTypes.any.isRequired,
  totalSellers: PropTypes.number.isRequired,
};

Results.defaultProps = {
  sellers: [],
};

export default Results;
