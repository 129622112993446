import axios from '../../utils/axios';

const getClientDetailViewData = async (clientId) => {
  const response = await axios.get(`/api/client/detail-view/${clientId}`);

  return response.data;
};

const getClientsPerPage = async ({
  page,
  limit,
  search,
  status,
  paymentConditions,
  sellerId,
  sortBy,
  sortDirection,
  userId,
}) => {
  const response = await axios.get(
    `/api/clients?page=${page}&limit=${limit}&search=${search}&status=${status}&paymentConditions=${paymentConditions}&sellerId=${sellerId}&sortBy=${sortBy}&sortDirection=${sortDirection}&userId=${userId}`,
  );

  return response.data;
};

const getAllClientsPurchases = async (clientIds, sortDirection) => {
  try {
    const response = await axios.post(`/api/clients/purchases?sortDirection=${sortDirection}`, {
      clientIds,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getClientsPerPageAdmin = async ({
  page,
  limit,
  search,
  status,
  paymentConditions,
  sellerId,
  sortBy,
  sortDirection,
  userId,
}) => {
  const response = await axios.get(`/api/admin/clients?page=${page}&limit=${limit}&search=${search}&status=${status}&paymentConditions=${paymentConditions}&sellerId=${sellerId}&sortBy=${sortBy}&sortDirection=${sortDirection}&userId=${userId}`);
  return response.data;
};

/**
 * Obtiene las direcciones de un cliente específico
 * @param {string} clientId - ID del cliente
 * @returns {Promise<Object>} - Objeto con las direcciones del cliente
 */
const getClientAddresses = async (clientId) => {
  try {
    const response = await axios.get(`/api/client/${clientId}/addresses`);
    return response.data;
  } catch (error) {
    console.error('Error al obtener direcciones del cliente:', error);
    throw error;
  }
};

/**
 * Obtiene las ventas diarias de un cliente en un rango de fechas específico
 * @param {string} clientId - ID del cliente
 * @param {string} startDate - Fecha de inicio en formato ISO (YYYY-MM-DD)
 * @param {string} endDate - Fecha de fin en formato ISO (YYYY-MM-DD)
 * @param {number} [directionId] - ID de la dirección del cliente (opcional)
 * @returns {Promise<Array>} - Array con las ventas diarias del cliente
 */
const getClientDailySales = async (clientId, startDate, endDate, directionId = null) => {
  try {
    const fixedStartDate = startDate.toISOString();
    const fixedEndDate = endDate.toISOString();
    let url = `/api/orders/client/${clientId}?startDate=${fixedStartDate}&endDate=${fixedEndDate}`;

    if (directionId) {
      url += `&directionId=${directionId}`;
    }

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error al obtener ventas diarias del cliente:', error);
    throw error;
  }
};

export {
  getClientDetailViewData,
  getClientsPerPage,
  getAllClientsPurchases,
  getClientsPerPageAdmin,
  getClientAddresses,
  getClientDailySales,
};
