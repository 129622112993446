/* eslint-disable react/display-name */
import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthGuard from './components/Auth/AuthGuard';
import AuthGuardSeller from './components/Auth/AuthGuardSeller';
import DashboardLayout from './components/Navigation';
import LoadingScreen from './components/Loading/LoadingScreen';
import { SELLER } from './constants/userRoles';

import Login from './views/Login';
import Verification from './views/Verification';
import PasswordRecovery from './views/PasswordRecovery';
import Register from './views/Register';
import PostRegister from './views/Register/PostRegister';

import UserListView from './views/Admin/Users/UserListView';
import UserCreateView from './views/Admin/Users/UserCreateView';
import UserEditView from './views/Admin/Users/UserEditView';

import ProductListView from './views/Product/ProductListView';
import ProductDetailView from './views/Product/ProductDetailView';

import ClientListView from './views/Client/ClientListView';
import ClientDetailView from './views/Client/ClientDetailView';

import OrderListView from './views/Order/OrderListView';
import OrderDetailView from './views/Order/OrderDetailView';

import SellerListView from './views/Seller/SellerListView';
import SellerDetailView from './views/Seller/SellerDetailView';
import ProductEditView from './views/Product/ProductEditView';
import DashboardView from './views/Seller/DashboardView';
import AdminDashboardView from './views/Admin/DashboardView';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />,
  },
  {
    id: 2,
    exact: true,
    path: '/login',
    component: () => <Login />,
  },
  // TODO: Si el usuario no puede realizar su propio registro
  // (desde fuera del Dashboard) comentar esta ruta.
  {
    id: 3,
    exact: true,
    path: '/register',
    component: () => <Register />,
  },
  {
    id: 4,
    exact: true,
    path: '/user-verification/:token',
    component: () => <Verification />,
  },
  {
    id: 5,
    exact: true,
    path: '/reset-password/:token',
    component: () => <PasswordRecovery />,
  },
  {
    id: 6,
    exact: true,
    path: '/reset-password?token=:token',
    component: () => <Redirect to="/reset-password/:token" />,
  },
  {
    id: 7,
    exact: true,
    path: '/postRegister',
    component: () => <PostRegister />,
  },
  {
    path: '/administracion',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        id: 11,
        exact: true,
        path: '/administracion',
        component: () => <Redirect to="/administracion/dashboard" />,
      },
      {
        id: 12,
        exact: true,
        path: '/administracion/dashboard',
        component: () => <AdminDashboardView />,
      },
      {
        id: 13,
        exact: true,
        path: '/administracion/users',
        component: () => <UserListView />,
      },
      {
        id: 14,
        exact: true,
        path: '/administracion/users/crear',
        component: () => <UserCreateView />,
      },
      {
        id: 15,
        exact: true,
        path: '/administracion/users/:id/editar',
        component: () => <UserEditView />,
      },
      {
        id: 16,
        exact: true,
        path: '/administracion/productos',
        component: () => <ProductListView />,
      },
      {
        id: 17,
        exact: true,
        path: '/administracion/productos/:id/detalle',
        component: () => <ProductDetailView />,
      },
      {
        id: 18,
        exact: true,
        path: '/administracion/clientes',
        component: () => <ClientListView isAdmin />,
      },
      {
        id: 19,
        exact: true,
        path: '/administracion/clientes/:id/detalle',
        component: () => <ClientDetailView />,
      },
      {
        id: 20,
        exact: true,
        path: '/administracion/pedidos/:id/detalle',
        component: () => <OrderDetailView />,
      },
      {
        id: 21,
        exact: true,
        path: '/administracion/pedidos',
        component: () => <OrderListView />,
      },
      {
        id: 22,
        exact: true,
        path: '/administracion/vendedores',
        component: () => <SellerListView />,
      },
      {
        id: 23,
        exact: true,
        path: '/administracion/vendedores/:id/detalle',
        component: () => <SellerDetailView />,
      },
      {
        id: 24,
        exact: true,
        path: '/administracion/productos/:id/editar',
        component: () => <ProductEditView />,
      },
      {
        id: 100,
        component: () => <Redirect to="/administracion/users" />,
      },
    ],
  },
  {
    path: '/vendedor/:sellerId?',
    guard: AuthGuardSeller,
    layout: DashboardLayout,
    routes: [
      {
        id: 23,
        exact: true,
        path: '/vendedor',
        component: () => {
          const account = useSelector((state) => state.account);
          if (account.user?.role === SELLER) {
            return <Redirect to={`/vendedor/${account.user.seller.id}/dashboard`} />;
          }
          return <Redirect to="/administracion/dashboard" />;
        },
      },
      {
        id: 24,
        exact: true,
        path: '/vendedor/:sellerId/dashboard',
        component: (props) => <DashboardView {...props} />,
      },
      {
        id: 25,
        exact: true,
        path: '/vendedor/:sellerId/clientes',
        component: (props) => <ClientListView {...props} />,
      },
      {
        id: 26,
        exact: true,
        path: '/vendedor/:sellerId/clientes/:id/detalle',
        component: (props) => <ClientDetailView {...props} />,
      },
      {
        id: 27,
        exact: true,
        path: '/vendedor/:sellerId/pedidos/:id/detalle',
        component: (props) => <OrderDetailView {...props} />,
      },
      {
        id: 28,
        exact: true,
        path: '/vendedor/:sellerId/pedidos',
        component: (props) => <OrderListView {...props} />,
      },
      {
        id: 29,
        exact: true,
        path: '/vendedor/:sellerId/mi-informacion',
        component: (props) => <SellerDetailView {...props} />,
      },
    ],
  },
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
