import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
  },
  tab: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 14,
    textTransform: 'none',
    color: '#5F696B',
    '&.Mui-selected': {
      color: '#62A606',
    },
  },
  indicator: {
    backgroundColor: '#62A606',
  },
}));

export default useStyles;
