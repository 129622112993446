import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Tabs from '../../../components/Tabs/Tabs';
import Dashboard from './Dashboard';
import {
  getClientDetailViewData,
} from '../../../requests/api/client';
import handleApiResponse from '../../../utils/handleApiResponse';
import useStyles from './styles';
import Information from './Information';
import { isSeller } from '../../../utils/roleUtils';
import Orders from './Orders';
// import Products from './Products';

function ClientDetailView(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const history = useHistory();
  const [client, setClient] = useState({});

  useEffect(() => {
    const getClient = async () => {
      try {
        const response = await getClientDetailViewData(props.match.params.id);
        setClient(response.client);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);

        if (isSeller(account)) {
          history.push('/vendedor/clientes');
        } else {
          history.push('/administracion/clientes');
        }
      }
    };

    if (props?.match?.params?.id) {
      getClient();
    }
    // eslint-disable-next-line
  }, [props]);

  const tabs = [
    {
      label: 'Dashboard',
      content: <Dashboard client={client} />,
    },
    {
      label: 'Pedidos',
      content: <Orders clientId={client.id ? client.id.toString() : ''} />,
    },
    // {
    //   label: 'Productos',
    //   content: <Products clientId={client.id ? client.id.toString() : ''} />,
    // },
    // {
    //   label: 'Direcciones',
    //   content: <div>Contenido de Direcciones</div>,
    // },
  ];

  return (
    <Page className={classes.root} title="Detalle del cliente">
      <Container maxWidth={false}>
        <Header client={client} />
        <Information client={client} />
        <Tabs tabs={tabs} />
      </Container>
    </Page>
  );
}

ClientDetailView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ClientDetailView);
