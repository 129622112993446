import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../utils/roleUtils';

const withAuth = (WrappedComponent, requiredRole) => function AuthenticatedComponent(props) {
  const account = useSelector((s) => s.account);
  const userRole = getUserRole(account);

  if (userRole === 'superAdmin' || userRole === requiredRole) {
    return <WrappedComponent {...props} />;
  }

  AuthenticatedComponent.propTypes = {
    userRole: PropTypes.object.isRequired,
  };
  return <Redirect to="/" />;
};

export default withAuth;
