import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    marginTop: theme.spacing(3),
  },
  gridContainer: {
    marginTop: theme.spacing(1),
  },
  chartContainer: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(3),
  },
  totalMonthGoal: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    padding: `${theme.spacing(2)} !important`,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  cardContainer: {
    height: '100%',
  },
}));

export default useStyles;
