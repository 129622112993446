import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import { getFirstDayOfCurrentMonth } from '../../../utils/date';

import { getSellersPerPage } from '../../../requests/api/seller';
import handleApiResponse from '../../../utils/handleApiResponse';
import useStyles from './styles';

function SellersListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [sellers, setSellers] = useState([]);
  const [totalSellers, setTotalSellers] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({
    search: '',
  });
  const currentMonth = getFirstDayOfCurrentMonth(new Date());
  const [sendQuery, setSendQuery] = useState(false);
  const sellersRedux = useSelector((state) => state.sellers);
  const [reduxFilters, setReduxFilters] = useState(false);
  // const dispatch = useDispatch();
  const user = useSelector((state) => state.account.user);
  const userArea = user.seller ? user.seller.area : '';

  useEffect(() => {
    const setReduxInfo = async () => {
      if (sellersRedux?.sellersFilters && Object.keys(sellersRedux.sellersFilters).length > 0) {
        setSendQuery((prev) => !prev);
      }
      setReduxFilters(true);
    };
    setReduxInfo();
  }, [sellersRedux]);

  useEffect(() => {
    const getSellers = async () => {
      try {
        const response = await getSellersPerPage({
          page,
          limit,
          search: filters.search,
          area: userArea,
        });
        setSellers(response.sellers);
        setTotalSellers(response.totalSellers);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };
    if (reduxFilters) {
      getSellers();
    }
  }, [sendQuery, reduxFilters, page, limit]);

  return (
    <Page
      className={classes.root}
      title="Vendedores"
    >
      <Container maxWidth={false}>
        <Header />
        {sellers && (
          <Box mt={3}>
            <Results
              sellers={sellers}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              setSendQuery={setSendQuery}
              currentMonth={currentMonth}
              totalSellers={totalSellers}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default SellersListView;
