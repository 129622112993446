import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    maxWidth: '1200px',
  },
  gridContainer: {
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& > div': {
      margin: '0 auto',
      width: '100%',
      maxWidth: '1172px',
      '& .highcharts-container': {
        margin: '0 auto',
      },
    },
  },
  headerIcon: {
    color: 'green',
    marginRight: '8px',
    width: '35px',
    height: '35px',
  },
  headerTitleDiv: {
    display: 'flex',
    alignItems: 'bottom',
    justifyContent: 'center',
    marginTop: '20px',
    marginLeft: '20px',
  },
  headerTitle: {
    fontSize: '24px',
  },
}));

export default useStyles;
