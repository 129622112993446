import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import { getOrdersPerPage } from '../../../requests/api/order';
import handleApiResponse from '../../../utils/handleApiResponse';
import useStyles from './styles';
import { getClientsPerPage } from '../../../requests/api/client';
import { getUserRole, isAdmin } from '../../../utils/roleUtils';

function OrdersListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loadingOrders, setLoadingOrders] = useState(false);
  const [clients, setClients] = useState([]);
  const [orders, setOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({
    search: '',
    status: [],
    startDate: '',
    endDate: '',
  });
  const [sendQuery, setSendQuery] = useState(false);
  const ordersRedux = useSelector((state) => state.orders);
  const account = useSelector((state) => state.account);
  const currentSeller = useSelector((state) => state.seller.currentSeller);

  const [reduxFilters, setReduxFilters] = useState(false);
  const [clientsPage, setClientsPage] = useState(0);
  const [clientSearch, setClientSearch] = useState('');
  const [hasMoreClients, setHasMoreClients] = useState(true);
  const [loadingClients, setLoadingClients] = useState(false);
  const clientsLimit = 20;

  let sellerId = 0;

  if (isAdmin(account)) {
    sellerId = currentSeller || 0;
  } else {
    sellerId = account.user.seller.id;
  }

  useEffect(() => {
    const setReduxInfo = async () => {
      if (ordersRedux?.ordersFilters && Object.keys(ordersRedux.ordersFilters).length > 0) {
        setFilters({ ...filters, ...ordersRedux.ordersFilters });
        setSendQuery((prev) => !prev);
      }
      setReduxFilters(true);
    };

    const getClients = async () => {
      try {
        const response = await getClientsPerPage({
          page,
          limit,
          search: filters.search,
          sellerId,
        });
        setClients(response.clients);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    getClients();
    setReduxInfo();
  }, [ordersRedux]);

  useEffect(() => {
    const getOrders = async () => {
      setLoadingOrders(true);
      const joinStatusFilter = filters.status.map((e) => e.value).join(',');
      try {
        const response = await getOrdersPerPage({
          page,
          limit,
          search: filters.search,
          status: joinStatusFilter,
          startDate: filters.startDate,
          endDate: filters.endDate,
          sellerId,
          clientId: filters.clientId || '',
          invoices: true,
        });
        setOrders(response.orders);
        setTotalOrders(response.totalOrders);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      } finally {
        setLoadingOrders(false);
      }
    };
    if (reduxFilters) {
      getOrders();
    }
  }, [sendQuery, reduxFilters, page, limit]);

  const loadMoreClients = async (search = '') => {
    if (loadingClients || (!hasMoreClients && clientsPage > 0)) return;

    setLoadingClients(true);
    try {
      const response = await getClientsPerPage({
        page: clientsPage,
        limit: clientsLimit,
        status: '',
        paymentConditions: '',
        search,
        sellerId,
      });

      if (clientsPage === 0) {
        setClients(response.clients || []);
      } else {
        setClients((prev) => [...prev, ...(response.clients || [])]);
      }

      setHasMoreClients(response.clients.length === clientsLimit);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
      setClients([]);
      setHasMoreClients(false);
    } finally {
      setLoadingClients(false);
    }
  };

  useEffect(() => {
    loadMoreClients();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setClientsPage(0);
      loadMoreClients(0, clientSearch);
    }, 500);

    return () => clearTimeout(timer);
  }, [clientSearch]);

  const handleLoadMoreClients = () => {
    const nextPage = clientsPage + 1;
    setClientsPage(nextPage);
    loadMoreClients(nextPage, clientSearch);
  };

  return (
    <Page
      className={classes.root}
      title="Pedidos"
    >
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
            <Results
              orders={orders}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              setSendQuery={setSendQuery}
              totalOrders={totalOrders}
              sellerRole={getUserRole(account)}
              clients={clients}
              onLoadMoreClients={handleLoadMoreClients}
              loadingClients={loadingClients}
              hasMoreClients={hasMoreClients}
              onClientSearch={setClientSearch}
              clientSearch={clientSearch}
              loadingOrders={loadingOrders}
              isAdmin={isAdmin(account)}
              sellerId={sellerId}
            />
          </Box>
      </Container>
    </Page>
  );
}

OrdersListView.propTypes = {
  isAdmin: PropTypes.bool,
};

export default OrdersListView;
