import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';
import { getAllClientsPurchases, getClientsPerPage, getClientsPerPageAdmin } from '../../../requests/api/client';
import handleApiResponse from '../../../utils/handleApiResponse';
import useStyles from './styles';
import { isSeller, getUserRole } from '../../../utils/roleUtils';

function ClientsListView({ isAdmin = false }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [clients, setClients] = useState([]);
  const [totalClients, setTotalClients] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState({
    search: '',
    status: [],
    paymentConditions: [],
    sortBy: 'updated_at',
    sortDirection: 'DESC',
  });
  const [sendQuery, setSendQuery] = useState(false);
  const clientsRedux = useSelector((state) => state.clients);
  const currentSeller = useSelector((state) => state.seller.currentSeller);
  const account = useSelector((state) => state.account);
  const [reduxFilters, setReduxFilters] = useState(false);

  let sellerId = 0;

  if (isSeller(account)) {
    sellerId = account.user.seller.id;
  } else if (currentSeller) {
    sellerId = currentSeller;
  }

  useEffect(() => {
    const setReduxInfo = async () => {
      if (clientsRedux?.clientsFilters && Object.keys(clientsRedux.clientsFilters).length > 0) {
        setFilters(clientsRedux.clientsFilters);
        setSendQuery((prev) => !prev);
      }
      setReduxFilters(true);
    };
    setReduxInfo();
  }, [clientsRedux]);

  useEffect(() => {
    const getPurchases = async (clientsIds, sortDirection) => {
      const response = await getAllClientsPurchases(clientsIds, sortDirection);
      return response?.clientsPurchases;
    };

    const getClients = async () => {
      const joinStatusFilter = filters.status.map((e) => e.value).join(',');
      const joinPaymentConditionsFilter = filters.paymentConditions.map((e) => e.value).join(',');
      try {
        let response;
        if (isAdmin) {
          response = await getClientsPerPageAdmin({
            page,
            limit,
            search: filters.search,
            status: joinStatusFilter,
            paymentConditions: joinPaymentConditionsFilter,
            sellerId,
            sortBy: filters.sortBy,
            sortDirection: filters.sortDirection,
          });
        } else {
          response = await getClientsPerPage({
            page,
            limit,
            search: filters.search,
            status: joinStatusFilter,
            paymentConditions: joinPaymentConditionsFilter,
            sellerId,
            sortBy: filters.sortBy,
            sortDirection: filters.sortDirection,
          });
        }
        const purchaseSort = filters.sortBy === 'purchases.lastOrderDate' ? filters.sortDirection : 'desc';
        const purchases = await getPurchases(response.clients.map((e) => e.id), purchaseSort);
        const fixedClients = response.clients.map((e) => {
          const purchase = purchases.find((p) => p.clientId === e.id);
          return { ...e, purchases: purchase };
        });

        setClients(fixedClients);
        setTotalClients(response.totalClients);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    if (reduxFilters) {
      getClients();
    }
  }, [sendQuery, reduxFilters, page, limit, filters, sellerId, enqueueSnackbar]);

  return (
    <Page className={classes.root} title="Clientes">
      <Container maxWidth={false}>
        <Header />
        {clients && (
          <Box mt={3}>
            <Results
              clients={clients}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              filters={filters}
              setFilters={setFilters}
              setSendQuery={setSendQuery}
              totalClients={totalClients}
              sellerRole={getUserRole(account)}
              isAdmin={isAdmin}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

ClientsListView.propTypes = {
  isAdmin: PropTypes.bool,
};

export default ClientsListView;
