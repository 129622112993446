import React from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Card from './Card';

const DataChart = ({
  series = [{}],
  meta = [],
  months,
  text,
  averageMarginData,
  averageMarginDataLastYear,
  data,
}) => {
  if (meta.length > 0) {
    series.push({
      name: 'Meta',
      type: 'line',
      data: meta,
      color: 'red',
      dashStyle: 'dash',
      marker: {
        enabled: false,
      },
    });
  }

  const chartOptions = {
    chart: {
      type: 'column',
      height: 400,
      width: null,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: months,
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        formatter() {
          return `$${Highcharts.numberFormat(this.value, 0, ',', '.')}`;
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      formatter() {
        if (!averageMarginData) {
          return `<b>${this.x}</b><br/>$${Highcharts.numberFormat(this.y, 0, ',', '.')}`;
        }
        const currentMarginData = averageMarginData.find((item) => item.month === this.x);
        const currentMonthData = data.find((item) => item.month === this.x);
        if (!currentMonthData) {
          return `<b>${this.x}</b><br/>No data available`;
        }
        const totalPedidos = currentMonthData?.totalInvoices;
        const totalVentas = currentMonthData?.totalSell;
        const ticketMedio = totalVentas / totalPedidos;
        const margenMedio = currentMarginData?.margin;

        let tooltip = `<b>${this.x}</b><br/>`;
        tooltip += `Total ventas: $${Highcharts.numberFormat(totalVentas, 0, ',', '.')}<br/>`;
        tooltip += `Total pedidos: ${totalPedidos}<br/>`;
        tooltip += `Ticket medio: $${Highcharts.numberFormat(ticketMedio, 0, ',', '.')}<br/>`;
        tooltip += `Margen medio: ${Highcharts.numberFormat(margenMedio, 2)}%<br/>`;

        const d = data.find((item) => item.month === this.x);

        if (d.lastYearMonth) {
          const ticketMedioLastYear = d.lastYearMonth.totalSell
            / d.lastYearMonth.totalInvoices;
          const margenMedioLastYear = averageMarginDataLastYear
            .find((item) => item.month === this.x)?.margin;

          tooltip += '<b>Año anterior</b><br/>';
          tooltip += `Total ventas: $${Highcharts.numberFormat(d.lastYearMonth.totalSell, 0, ',', '.')}<br/>`;
          tooltip += `Total pedidos: ${d.lastYearMonth.totalInvoices}<br/>`;
          tooltip += `Ticket medio: $${Highcharts.numberFormat(ticketMedioLastYear, 0, ',', '.')}<br/>`;
          tooltip += `Margen medio: ${Highcharts.numberFormat(margenMedioLastYear, 2)}%<br/>`;
        }

        return tooltip;
      },
    },
    plotOptions: {
      column: {
        borderRadius: 3,
        pointPadding: series.length > 1 ? 0.05 : 0.2,
        groupPadding: series.length > 1 ? 0.1 : 0.2,
        borderWidth: 0,
      },
    },
    series,
    credits: {
      enabled: false,
    },
  };

  return (
    <Card widthPercentage={80} maxWidth={1172} height={550}>
      <div style={{ padding: '16px', width: '100%' }}>
        <div style={{
          display: 'flex', alignItems: 'center', marginBottom: '16px', flexWrap: 'wrap', gap: '16px',
        }}>
          <h2 style={{ fontSize: '25px', fontWeight: 'bold', margin: '0 0 20px 0' }}>
            <i className="fa fa-chart-bar"></i> {text}
          </h2>
        </div>
        <div style={{
          display: 'flex', alignItems: 'center', marginBottom: '16px', flexWrap: 'wrap', gap: '16px',
        }}>
          {series.map((serie, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{
                width: '12px',
                height: '12px',
                backgroundColor: serie.color,
                marginRight: '8px',
              }} />
              <span style={{ fontSize: '14px', color: '#666', marginRight: '16px' }}>
                {serie.name}
              </span>
            </div>
          ))}
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    </Card>
  );
};

DataChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.number).isRequired,
  })),
  meta: PropTypes.arrayOf(PropTypes.number),
  months: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string,
  averageMarginData: PropTypes.array,
  averageMarginDataLastYear: PropTypes.array,
  data: PropTypes.array,
};

export default DataChart;
