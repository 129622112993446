import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { isAdmin, isSeller } from '../../utils/roleUtils';

function AuthGuardSeller({ children }) {
  const { sellerId } = useParams();
  const account = useSelector((state) => state.account);
  const location = useLocation();

  if (!account.user) {
    return <Redirect to="/login" />;
  }

  // Si es vendedor
  if (isSeller(account)) {
    const expectedPath = `/vendedor/${account.user.seller.id}`;
    // Si la URL no contiene el ID del vendedor, redirigir a su dashboard
    if (!location.pathname.includes(expectedPath)) {
      return <Redirect to={`${expectedPath}/dashboard`} />;
    }
    return children;
  }

  // Si es admin o superadmin
  if (isAdmin(account)) {
    if (sellerId) {
      return children;
    }
    return <Redirect to="/administracion/dashboard" />;
  }

  // Si no es vendedor ni admin, redirigir a login
  return <Redirect to="/login" />;
}

AuthGuardSeller.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuardSeller;
