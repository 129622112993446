import axios from '../../utils/axios';

const getProductById = async (productId) => {
  const response = await axios.get(`/api/product/${productId}`);

  return response.data;
};

const getProductStocks = async (productId) => {
  const response = await axios.get(`/api/product/${productId}/stocks`);

  return response.data;
};

const getProductsPerPage = async ({
  page, limit, search, active, stock,
}) => {
  const response = await axios.get(
    `/api/products?page=${page}&limit=${limit}&search=${search}&active=${active}&stock=${stock}`,
  );

  return response.data;
};

const putProductById = async ({
  sapId,
  active,
  name,
  brand,
  content,
  category,
  subCategory,
}) => {
  const response = await axios.put('/api/product/edit', {
    sapId,
    active,
    name,
    brand,
    content,
    category,
    subCategory,
  });
  return response.data;
};

const getClientProducts = async (clientId, {
  page = 1,
  limit = 10,
  search = '',
  startDate,
  endDate,
  timeFilter,
}) => {
  // Construir los parámetros de consulta
  const queryParams = new URLSearchParams();

  // Agregar parámetros básicos
  queryParams.append('page', page);
  queryParams.append('limit', limit);
  if (search) queryParams.append('search', search);

  // Agregar parámetros de fecha
  if (startDate) queryParams.append('startDate', startDate);
  if (endDate) queryParams.append('endDate', endDate);

  // Para compatibilidad con código existente
  if (timeFilter && !startDate && !endDate) {
    queryParams.append('timeFilter', timeFilter);
  }

  const url = `/api/clients/${clientId}/products?${queryParams.toString()}`;
  console.log('Realizando petición a:', url);

  const response = await axios.get(url);

  console.log('Respuesta del servidor (product.js):', response.status);
  console.log('Datos recibidos (product.js):', JSON.stringify(response.data, null, 2));

  return response.data;
};

const getClientTopProducts = async (clientId, timeParams = {}) => {
  // Construir los parámetros de consulta
  const queryParams = new URLSearchParams();

  // Si timeParams es un string (para compatibilidad con código existente), usarlo como timeFilter
  if (typeof timeParams === 'string') {
    queryParams.append('timeFilter', timeParams);
  } else {
    // Si es un objeto, agregar startDate y endDate si existen
    if (timeParams.startDate) {
      queryParams.append('startDate', timeParams.startDate);
    }
    if (timeParams.endDate) {
      queryParams.append('endDate', timeParams.endDate);
    }
  }

  const response = await axios.get(
    `/api/client/${clientId}/top-products?${queryParams.toString()}`,
  );

  return response.data;
};

const getClientRecentProducts = async (clientId, timeParams = {}) => {
  // Construir los parámetros de consulta
  const queryParams = new URLSearchParams();

  // Si timeParams es un string (para compatibilidad con código existente), usarlo como timeFilter
  if (typeof timeParams === 'string') {
    queryParams.append('timeFilter', timeParams);
  } else {
    // Si es un objeto, agregar startDate y endDate si existen
    if (timeParams.startDate) {
      queryParams.append('startDate', timeParams.startDate);
    }
    if (timeParams.endDate) {
      queryParams.append('endDate', timeParams.endDate);
    }
  }

  const response = await axios.get(
    `/api/client/${clientId}/recent-products?${queryParams.toString()}`,
  );

  return response.data;
};

export {
  getProductById,
  getProductStocks,
  getProductsPerPage,
  putProductById,
  getClientProducts,
  getClientTopProducts,
  getClientRecentProducts,
};
