import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { withRouter, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Seller from './Seller';
import { getSellerDetailViewData } from '../../../requests/api/seller';
import handleApiResponse from '../../../utils/handleApiResponse';
import useStyles from './styles';
import { isSeller } from '../../../utils/roleUtils';

function SellerDetailView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const { currentSeller } = useSelector((state) => state.seller || { currentSeller: null });
  const { sellerId } = useParams();

  const [seller, setSeller] = useState({});

  // Determinar qué ID de vendedor usar
  const getEffectiveSellerId = () => {
    if (isSeller(account)) {
      return account.user.seller.id;
    }
    return sellerId || currentSeller;
  };

  useEffect(() => {
    const getSeller = async () => {
      const effectiveSellerId = getEffectiveSellerId();
      if (!effectiveSellerId) return;

      try {
        const response = await getSellerDetailViewData(effectiveSellerId);
        setSeller(response.seller);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    getSeller();
  }, [sellerId, currentSeller, account.user, enqueueSnackbar]);

  if (!seller) {
    return null;
  }

  return (
    <Page className={classes.root} title="Detalle del vendedor">
      <Container maxWidth={false}>
        <Header />
        <div>
          <Seller seller={seller} />
        </div>
      </Container>
    </Page>
  );
}

SellerDetailView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(SellerDetailView);
