import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import moment from 'moment';
import 'moment/locale/es';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';

import Metric from './Metric';
import DataChart from './DataChart';
import useStyles from './styles';

const AdminContent = ({
  dashboardData,
  goals,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (dashboardData?.monthsOfThisYear) {
      setIsLoading(false);
    }
  }, [dashboardData]);

  // Calcular datos para las métricas
  const totalVentasActual = dashboardData?.monthsOfThisYear?.reduce(
    (acc, month) => acc + month.totalSell, 0,
  ) || 0;

  const totalVentasAnterior = dashboardData?.monthsOfLastYear?.reduce(
    (acc, month) => acc + month.totalSell, 0,
  ) || 1;

  const totalFacturasActual = dashboardData?.monthsOfThisYear?.reduce(
    (acc, month) => acc + month.totalInvoices, 0,
  ) || 0;

  const totalFacturasAnterior = dashboardData?.monthsOfLastYear?.reduce(
    (acc, month) => acc + month.totalInvoices, 0,
  ) || 1;

  const totalClientesActual = dashboardData?.monthsOfThisYear?.reduce(
    (acc, month) => acc + month.totalClientDirections, 0,
  ) || 0;

  const totalClientesAnterior = dashboardData?.monthsOfLastYear?.reduce(
    (acc, month) => acc + month.totalClientDirections, 0,
  ) || 1;

  const chartObjetives = {
    series: [{
      name: 'Venta',
      color: '#67B231',
      data: dashboardData?.monthsOfThisYear?.map((item) => item.totalSell) || [],
    }],
    text: 'Meta vs Venta (Todos los vendedores)',
    months: dashboardData?.monthsOfThisYear?.map((item) => item.month) || [],
    goals,
    data: dashboardData?.monthsOfThisYear || [],
  };

  const chartComparatives = {
    series: [
      {
        name: '2024',
        color: '#67B231',
        data: dashboardData?.monthsOfLastYear?.map((item) => item.totalSell) || [],
      },
      {
        name: '2025',
        color: '#24335E',
        data: dashboardData?.monthsOfThisYear?.map((item) => item.totalSell) || [],
      }],
    text: 'Comparativo de venta vs año anterior (Todos los vendedores)',
    months: dashboardData?.monthsOfThisYear?.map((item) => item.month) || [],
    monthsLastYear: dashboardData?.monthsOfLastYear?.map((item) => item.month) || [],
    data: dashboardData?.monthsOfThisYear?.map((item) => {
      const lastYearMonth = dashboardData?.monthsOfLastYear
        ?.find((month) => month.month === item.month);
      return {
        ...item,
        lastYearMonth,
      };
    }) || [],
  };

  // Limitamos a los 10 grupos con mayor venta para mejor visualización
  const sortedArticleGroups = dashboardData?.totalSellByArticleGroup
    ? [...dashboardData.totalSellByArticleGroup]
      .sort((a, b) => b.totalSell - a.totalSell)
      .slice(0, 10)
    : [];

  const chartArticleGroups = {
    series: [{
      name: 'Ventas',
      color: '#24335E',
      data: sortedArticleGroups?.map((item) => item.totalSell) || [],
    }],
    text: 'Top 10 Ventas por Grupo de Artículos',
    months: sortedArticleGroups?.map((item) => item.articleGroup.name) || [],
    data: sortedArticleGroups || [],
  };

  // Datos para el gráfico de top 5 clientes
  const chartTopClients = {
    series: [{
      name: 'Ventas',
      color: '#FEB019',
      data: dashboardData?.top5Clients?.map((item) => item.totalSell) || [],
    }],
    text: 'Top 5 Clientes',
    months: dashboardData?.top5Clients?.map((item) => item.client.name) || [],
    data: dashboardData?.top5Clients || [],
  };

  const renderMetricOrSkeleton = (props) => {
    if (isLoading) {
      return (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={120}
          animation="wave"
        />
      );
    }
    return <Metric {...props} />;
  };

  const renderTotalMonthGoal = () => {
    let totalMonthGoal = '-';
    if (goals?.length > 0) {
      totalMonthGoal = Highcharts.numberFormat(goals[moment().month()] || 0, 0, ',', '.');
    }
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography color="text.primary" variant="h5">
          {`META TOTAL VENTAS ${moment().format('MMMM').toUpperCase()}:`}
        </Typography>
        <Typography color="text.primary" variant="h5" style={{ fontWeight: 'bold' }}>
          {`$${totalMonthGoal}`}
        </Typography>
      </Box>
    );
  };

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xs={12} sm={6} md={3}>
          {renderMetricOrSkeleton({
            title: 'Total Vendedores',
            amount: dashboardData?.totalSellers || 0,
            percentage: '100',
            percentageText: 'activos',
            hidePrefix: true,
          })}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderMetricOrSkeleton({
            title: 'Ventas Totales',
            amount: totalVentasActual,
            percentage: ((totalVentasActual * 100) / totalVentasAnterior).toFixed(2),
            percentageText: 'vs año anterior',
          })}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderMetricOrSkeleton({
            title: 'Total Facturas',
            amount: totalFacturasActual,
            percentage: ((totalFacturasActual * 100) / totalFacturasAnterior).toFixed(2),
            percentageText: 'vs año anterior',
            hidePrefix: true,
          })}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderMetricOrSkeleton({
            title: 'Total Clientes',
            amount: totalClientesActual,
            percentage: ((totalClientesActual * 100) / totalClientesAnterior).toFixed(2),
            percentageText: 'vs año anterior',
            hidePrefix: true,
          })}
        </Grid>

        <Grid item xs={12} className={classes.totalMonthGoal}>
          {renderTotalMonthGoal()}
        </Grid>

        <Grid item xs={12} className={classes.chartContainer}>
          <DataChart {...chartObjetives} />
        </Grid>

        <Grid item xs={12} className={classes.chartContainer}>
          <DataChart {...chartComparatives} />
        </Grid>

        <Grid item xs={12} className={classes.chartContainer}>
          <DataChart {...chartArticleGroups} />
        </Grid>

        <Grid item xs={12} className={classes.chartContainer}>
          <DataChart {...chartTopClients} />
        </Grid>
      </Grid>
    </div>
  );
};

AdminContent.propTypes = {
  dashboardData: PropTypes.object,
  goals: PropTypes.array,
  averageMarginData: PropTypes.array,
  averageMarginDataLastYear: PropTypes.array,
};

AdminContent.defaultProps = {
  dashboardData: {},
  goals: [],
  averageMarginData: [],
  averageMarginDataLastYear: [],
};

export default AdminContent;
