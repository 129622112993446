import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import useStyles from './styles';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import AdminContent from './AdminContent';
import { getAdminDashboardData } from '../../../requests/api/admin';
import handleApiResponse from '../../../utils/handleApiResponse';

function AdminDashboardView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [dashboardData, setDashboardData] = useState({});
  const [goals, setGoals] = useState([]);
  const averageMargin = [];
  const averageMarginLastYear = [];

  const getDashboardData = async () => {
    try {
      const response = await getAdminDashboardData();
      if (response && response.dashboardData) {
        setDashboardData(response.dashboardData);
        setGoals(response.dashboardData.thisYearObjectives || []);
      }
    } catch (error) {
      handleApiResponse(enqueueSnackbar, error, false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <Page className={classes.root} title="Dashboard Administrativo">
      <Container maxWidth={false}>
        <Header />
        <AdminContent
          dashboardData={dashboardData}
          goals={goals}
          averageMarginData={averageMargin}
          averageMarginDataLastYear={averageMarginLastYear}
        />
      </Container>
    </Page>
  );
}

export default withRouter(AdminDashboardView);
