import { ADMIN, SELLER, SUPER_ADMIN } from '../constants/userRoles';

/**
 * Checks if the user has a specific role
 * @param {Object} account - The account object from Redux state
 * @param {String} role - The role to check
 * @returns {Boolean} - true if the user has the specified role, false otherwise
 */
export const hasRole = (account, role) => {
  if (!account || !account.user) return false;
  return account.user.role === role;
};

/**
 * Checks if the user is an administrator (admin or superadmin)
 * @param {Object} account - The account object from Redux state
 * @returns {Boolean} - true if the user is admin or superadmin, false otherwise
 */
export const isAdmin = (account) => {
  if (!account || !account.user) return false;
  return [ADMIN, SUPER_ADMIN].includes(account.user.role);
};

/**
 * Checks if the user is a super administrator
 * @param {Object} account - The account object from Redux state
 * @returns {Boolean} - true if the user is superadmin, false otherwise
 */
export const isSuperAdmin = (account) => {
  if (!account || !account.user) return false;
  return account.user.role === SUPER_ADMIN;
};

/**
 * Checks if the user is a seller
 * @param {Object} account - The account object from Redux state
 * @returns {Boolean} - true if the user is a seller, false otherwise
 */
export const isSeller = (account) => {
  if (!account || !account.user) return false;
  return account.user.role === SELLER;
};

/**
 * Gets the seller ID if the user is a seller
 * @param {Object} account - The account object from Redux state
 * @returns {Number|null} - The seller ID or null if the user is not a seller
 */
export const getSellerId = (account) => {
  if (!isSeller(account) || !account.user.seller) return null;
  return account.user.seller.id;
};

/**
 * Gets the user role
 * @param {Object} account - The account object from Redux state
 * @returns {String|null} - The user role or null if there is no user
 */
export const getUserRole = (account) => {
  if (!account || !account.user) return null;
  return account.user.role;
};
