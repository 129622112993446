import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'nprogress/nprogress.css';
import './assets/css/prism.css';
import './mixins/chartjs';
import './mixins/prismjs';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import { SettingsProvider } from './context/SettingsContext';
import store, { persistor } from './store';
import { restoreSettings } from './utils/settings';
import App from './App';

enableES5();

const settings = restoreSettings();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SettingsProvider settings={settings}>
        <App />
      </SettingsProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
