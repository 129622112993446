import axios from '../../utils/axios';
import { SELLER } from '../../constants/userRoles';

export const SET_SELLERS = 'SET_SELLERS';
export const SET_CURRENT_SELLER = 'SET_CURRENT_SELLER';
export const SELLERS_ERROR = 'SELLERS_ERROR';
export const RESET_SELLER_STATE = 'RESET_SELLER_STATE';

export const setSellers = (sellers) => ({
  type: SET_SELLERS,
  payload: sellers,
});

export const setCurrentSeller = (seller) => ({
  type: SET_CURRENT_SELLER,
  payload: seller,
});

export const sellersError = (error) => ({
  type: SELLERS_ERROR,
  payload: error,
});

export const resetSellerState = () => ({
  type: RESET_SELLER_STATE,
});

export const fetchSellers = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/users?page=0&limit=100&searchValue=&sort=updated_at%7CDESC');

    if (!response.data || !response.data.users) {
      return;
    }
    const sellers = response.data.users
      .filter((user) => user.role === SELLER && user.active)
      .map((user) => ({
        id: user.seller.id.toString(),
        name: user.seller.name,
      }));

    dispatch(setSellers(sellers));
  } catch (error) {
    dispatch(sellersError(error.message));
  }
};

export const updateCurrentSeller = (sellerId) => async (dispatch) => {
  try {
    dispatch(setCurrentSeller(sellerId));
  } catch (error) {
    dispatch(sellersError(error.message));
  }
};
