import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },

  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  shoppingBagIcon: {
    fontSize: '33px',
    marginRight: '8px',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
  headerTitleDiv: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0px',
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: 700,
    fontFamily: theme.typography.Inter.fontFamily,
    color: theme.palette.text.primary,
  },
  firstSquare: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    marginTop: '30px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  leftColumn: {
    width: '49%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  rightColumn: {
    width: '49%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '10px',
    },
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  card: {
    minHeight: '400px',
  },
  scrollableTable: {
    overflowY: 'auto',
    height: '400px',
    // overflowX: 'hidden',
  },

  cardHeaderDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '15px',
    marginBottom: '10px',
  },
  cardTitleDiv: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 20px 10px 20px',
  },
  cardTitle: {
    fontSize: '18px',
    fontWeight: 700,
    fontFamily: theme.typography.Inter.fontFamily,
    color: theme.palette.text.primary,
  },
  queryGrid: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 20px 0px 20px',
  },
  queryField: {
    width: 400,
  },

  progressChartDiv: {
    position: 'relative',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  },

  progressChartTitle: {
    position: 'absolute',
    top: 0,
    left: 0,
    marginLeft: '10px',
    marginTop: '10px',
    fontWeight: 'bold',
    fontSize: '18px',
    fontFamily: theme.typography.Inter.fontFamily,
  },
  arrowIcon: {
    marginRight: '5px',
    verticalAlign: 'middle',
    color: '#62A606',
    fontSize: '40px',
  },

  secondSquare: {
    height: '100%',
    backgroundColor: '#FFFFFF',
    marginTop: '30px',
  },

  timeLineIcon: {
    margin: '20px',
    verticalAlign: 'middle',
    color: '#62A606',
    fontSize: '40px',
  },
  dataChartTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    fontFamily: theme.typography.Inter.fontFamily,
    color: theme.palette.text.primary,
  },
  chartButtonsDiv: {
    marginLeft: '10px',
    marginBottom: '40px',
  },
  buttons: {
    marginRight: '10px',
    marginLeft: '10px',
    textAlign: 'center',
    width: '200px',
    height: '40px',
    fontSize: '11px',
    fontWeight: 700,
    fontFamily: theme.typography.Inter.fontFamily,
    backgroundColor: theme.palette.secondary.main,
  },
  buttonsSelected: {
    backgroundColor: theme.palette.primary.main,
  },
  searchButton: {
    minWidth: '200px',
    margin: '0px 20px 0px 0px',
    fontSize: '11px',
    fontWeight: 700,
    fontFamily: theme.typography.Inter.fontFamily,
  },
  warningDiv: {
    margin: '20px 0px 20px 0px',
  },
  warningIcon: {
    fontSize: '33px',
    marginRight: '8px',
    alignItems: 'center',
  },
  boldText: {
    fontWeight: 'bold',
  },

  informationCard: {
    margin: '20px 0px 50px 0px',
  },
}));

export default useStyles;
