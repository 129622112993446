import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Grid,
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from '@mui/material';
import useStyles from './styles';

const Information = ({ client }) => {
  const classes = useStyles();

  // if (Object.keys(client).length === 0) {
  //   return null;
  // }

  return (
    <Card className={classes.informationCard}>
      <PerfectScrollbar>
        <Grid container justifyContent="space-between" >
          <Grid item xs={12}>
            <Table stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.boldText}>RUT</TableCell>
                  <TableCell className={classes.boldText}>Razón Social</TableCell>
                  <TableCell className={classes.boldText}>Estado</TableCell>
                  <TableCell className={classes.boldText}>Condicion de pago</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover key={client?.id}>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {client?.rut || '1009000'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {client?.name || 'Nombre del cliente'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {client?.status || 'Estado'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {client?.paymentCondition?.name || 'Condicion de pago'}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
};

Information.propTypes = {
  client: PropTypes.any,
};

export default Information;
