import axios from '../../utils/axios';

/**
 * Obtiene los datos del dashboard de administrador
 * @returns {Promise<Object>} Datos del dashboard
 */
const getAdminDashboardData = async () => {
  try {
    const response = await axios.get('/api/admin/dashboard');
    return response.data;
  } catch (error) {
    console.error('Error al obtener datos del dashboard de administrador:', error);
    throw error;
  }
};

const getClientsPerPageAdmin = async () => {
  try {
    const response = await axios.get('/api/admin/clients');
    return response.data;
  } catch (error) {
    console.error('Error al obtener datos de los clientes:', error);
    throw error;
  }
};

export {
  getAdminDashboardData,
  getClientsPerPageAdmin,
};
