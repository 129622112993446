import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const Metric = ({
  title,
  amount,
  amountSuffix,
  percentage,
  percentageText,
  hidePrefix,
}) => (
  <Card sx={{
    width: '100%',
    maxWidth: 278,
    maxHeight: 165,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 2,
  }}>
    <CardContent sx={{
      padding: '16px',
      height: '100%',
      '&:last-child': {
        paddingBottom: '16px',
      },
    }}>
      <Typography
        variant="subtitle1"
        color="text.secondary"
        gutterBottom
      >
        {title}
      </Typography>

      <Typography
        variant="h4"
        component="div"
        sx={{
          fontWeight: 'bold',
          my: 2,
        }}
      >
        {!hidePrefix && '$'}
        {new Intl.NumberFormat('es-AR').format(amount || 0)}
        {amountSuffix && ` ${amountSuffix}`}
      </Typography>

      <Divider sx={{ my: 1 }} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          variant="body2"
          sx={{
            color: percentage >= 0 ? 'success.main' : 'error.main',
          }}
        >
          {percentage}% {percentageText}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

Metric.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number,
  amountSuffix: PropTypes.string,
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentageText: PropTypes.string,
  hidePrefix: PropTypes.bool,
};

Metric.defaultProps = {
  amount: 0,
  amountSuffix: '',
  percentage: 0,
  percentageText: '',
  hidePrefix: false,
};

export default Metric;
