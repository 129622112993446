import React from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';

const Header = () => (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Dashboard Administrativo
        </Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
        >
          Resumen de datos de todos los vendedores
        </Typography>
      </Grid>
    </Grid>
);

export default Header;
