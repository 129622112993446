/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath, useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';

import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';

import Logo from '../../Layout/Logo';
import NavItem from './NavItem';
import useStyles from './styles';

import { ADMIN, SELLER, SUPER_ADMIN } from '../../../constants/userRoles';
import { fetchSellers, updateCurrentSeller } from '../../../store/actions/sellerActions';

const adminNav = [
  {
    items: [
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        href: '/administracion/dashboard',
      },
      {
        title: 'Usuarios',
        icon: PersonOutlineIcon,
        href: '/administracion/users',
      },
      {
        title: 'Clientes',
        icon: PeopleAltIcon,
        href: '/administracion/clientes',
      },
      {
        title: 'Pedidos',
        icon: ShoppingCartIcon,
        href: '/administracion/pedidos',
      },
      {
        title: 'Productos',
        icon: ShoppingBagIcon,
        href: '/administracion/productos',
      },
      {
        title: 'Vendedores',
        icon: AttachMoneyIcon,
        href: '/administracion/vendedores',
      },
    ],
  },
];

const sellerNav = [
  {
    items: [
      {
        title: 'Dashboard',
        icon: HomeIcon,
        href: '/vendedor/dashboard',
      },
      {
        title: 'Clientes',
        icon: PeopleAltIcon,
        href: '/vendedor/clientes',
      },
      {
        title: 'Pedidos',
        icon: ShoppingCartIcon,
        href: '/vendedor/pedidos',
      },
      {
        title: 'Mi información',
        icon: PersonOutlineIcon,
        href: '/vendedor/mi-informacion',
      },
    ],
  },
];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc, pathname, item, depth = 0,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />,
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { sellers, currentSeller } = useSelector(
    (state) => state.seller || { sellers: [], currentSeller: null },
  );

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if ([ADMIN, SUPER_ADMIN].includes(account.user?.role)) {
      dispatch(fetchSellers());
    }
  }, [dispatch, account.user?.role]);

  const handleSellerChange = (sellerId) => {
    dispatch(updateCurrentSeller(sellerId));

    // Redirigir al dashboard del vendedor seleccionado
    if (sellerId) {
      history.push(`/vendedor/${sellerId}/dashboard`);
    }
  };

  const renderSellerSelector = () => {
    if (!Array.isArray(sellers) || sellers.length === 0) {
      return null;
    }

    return (
      <Box p={2} mb={2}>
        <Typography variant="subtitle2" color="textSecondary" mb={1}>
          Vendedor
        </Typography>
        <FormControl fullWidth>
          <Select
            value={currentSeller || ''}
            onChange={(e) => handleSellerChange(e.target.value)}
            variant="outlined"
            size="small"
            displayEmpty
          >
            <MenuItem value="">
              <em>Seleccione un vendedor</em>
            </MenuItem>
            {sellers.map((seller) => (
              <MenuItem key={seller.id} value={seller.id}>
                {seller.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  };

  const getSellerBasePath = () => {
    if (account.user?.role === SELLER) {
      return `/vendedor/${account.user.seller.id}`;
    }
    return currentSeller ? `/vendedor/${currentSeller}` : '/vendedor';
  };

  const renderNav = () => {
    let navConfig = [];
    const isAdmin = [ADMIN, SUPER_ADMIN].includes(account.user?.role);
    const basePath = getSellerBasePath();

    if (isAdmin) {
      return (
        <React.Fragment>
          {/* Primero renderizamos el menú de administración */}
          {adminNav.map((config, index) => (
            <List key={`admin-${index}`}>
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}

          {/* Separador personalizado */}
          <Divider sx={{
            backgroundColor: '#E0E0E0',
            height: '2px',
            margin: '16px 0',
          }} />

          {/* Luego el selector de vendedor */}
          {renderSellerSelector()}

          {/* Finalmente el menú del vendedor si hay uno seleccionado */}
          {currentSeller && sellerNav.map((section) => {
            const navWithId = {
              ...section,
              items: section.items.map((item) => ({
                ...item,
                href: item.href.replace('/vendedor', basePath),
              })),
            };
            return (
              <List key={`seller-${currentSeller}`}>
                {renderNavItems({
                  items: navWithId.items,
                  pathname: location.pathname,
                })}
              </List>
            );
          })}
        </React.Fragment>
      );
    } if (account.user?.role === SELLER) {
      navConfig = sellerNav.map((section) => ({
        ...section,
        items: section.items.map((item) => ({
          ...item,
          href: item.href.replace('/vendedor', basePath),
        })),
      }));

      return (
        <React.Fragment>
          {navConfig.map((config, index) => (
            <List key={index}>
              {renderNavItems({
                items: config.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </React.Fragment>
      );
    }

    return null;
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/">
              <Avatar alt="User" className={classes.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {account.user && account.user.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {account.user && account.user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>{renderNav()}</Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
