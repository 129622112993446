import {
  SET_SELLERS,
  SET_CURRENT_SELLER,
  SELLERS_ERROR,
  RESET_SELLER_STATE,
} from '../actions/sellerActions';

const initialState = {
  sellers: [],
  currentSeller: null,
  error: null,
  loading: false,
};

const sellerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELLERS:
      return {
        ...state,
        sellers: Array.isArray(action.payload) ? action.payload : [],
        error: null,
        loading: false,
      };

    case SET_CURRENT_SELLER:
      return {
        ...state,
        currentSeller: action.payload,
        error: null,
      };

    case SELLERS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case RESET_SELLER_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default sellerReducer;
