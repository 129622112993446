import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

// Reducers
import accountReducer from './reducers/accountReducer';
import clientsReducer from './reducers/clientsReducer';
import ordersReducer from './reducers/ordersReducer';
import sellerReducer from './reducers/sellerReducer';
import productsReducer from './reducers/productsReducer';

// Combine all reducers
const rootReducer = combineReducers({
  account: accountReducer,
  clients: clientsReducer,
  orders: ordersReducer,
  products: productsReducer,
  seller: sellerReducer,
  form: formReducer,
});

export default rootReducer;
