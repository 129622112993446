import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  InputAdornment,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { DatePicker } from '@mui/x-date-pickers';

import { getClientOrders } from '../../../requests/api/order';
import handleApiResponse from '../../../utils/handleApiResponse';
import { isSeller } from '../../../utils/roleUtils';

const OrderSummaryCard = ({ value, label }) => (
  <Card>
    <CardContent style={{ textAlign: 'center', padding: '20px' }}>
      <Typography variant="h3" component="h2">
        {value}
      </Typography>
      <Typography color="textSecondary">
        {label}
      </Typography>
    </CardContent>
  </Card>
);

const Orders = ({ clientId }) => {
  let sellerId = 0;
  const [orders, setOrders] = useState([]);
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    blocked: 0,
    invoiced: 0,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // Material-UI usa 0-indexed para páginas
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);
  const currentSeller = useSelector((state) => state.seller.currentSeller);

  if (isSeller(account)) {
    sellerId = account.user.seller.id;
  } else if (currentSeller && currentSeller.id) {
    sellerId = currentSeller.id;
  }

  const fetchOrders = async (pageNumber, limit, search) => {
    if (!clientId) return;

    setLoading(true);
    try {
      // Preparar los parámetros de fecha si están presentes
      const dateParams = {};
      if (startDate) {
        const [dateOnly] = startDate.toISOString().split('T');
        dateParams.startDate = dateOnly;
      }
      if (endDate) {
        const [dateOnly] = endDate.toISOString().split('T');
        dateParams.endDate = dateOnly;
      }

      const data = await getClientOrders(clientId, {
        page: pageNumber + 1, // Convertimos a 1-indexed para la API
        limit,
        search,
        ...dateParams,
      });

      // Configurar los pedidos
      setOrders(data.orders || []);

      // Configurar las estadísticas
      setStats({
        total: data.stats?.total || 0,
        active: data.stats?.active || 0,
        blocked: data.stats?.blocked || 0,
        invoiced: data.stats?.invoiced || 0,
      });

      // Configurar la paginación
      setTotalCount(data.pagination?.total || 0);
    } catch (err) {
      handleApiResponse(enqueueSnackbar, err, false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders(page, rowsPerPage, searchTerm);
  }, [clientId, page, rowsPerPage, enqueueSnackbar]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter') {
      setPage(0); // Resetear a la primera página al buscar
      fetchOrders(0, rowsPerPage, searchTerm);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateChange = () => {
    setPage(0); // Resetear a la primera página al cambiar fechas
    fetchOrders(0, rowsPerPage, searchTerm);
  };

  if (loading && orders.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {/* Tarjetas de resumen */}
      <Grid container spacing={3} style={{ marginBottom: '24px' }}>
        <Grid item xs={12} sm={6} md={3}>
          <OrderSummaryCard value={stats.total} label="Pedido totales" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <OrderSummaryCard value={stats.active} label="Pedido activos" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <OrderSummaryCard value={stats.blocked} label="Pedido bloqueados" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <OrderSummaryCard value={stats.invoiced} label="Pedido Facturados" />
        </Grid>
      </Grid>

      {/* Filtros y buscador */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} flexWrap="wrap" gap={2}>
        <Box display="flex" alignItems="center">
          <ShoppingCartIcon style={{ marginRight: '8px', color: '#4CAF50' }} />
          <Typography variant="h5" component="h2">
            Pedidos
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
          <DatePicker
            label="Fecha inicio"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
              if (newValue || endDate) handleDateChange();
            }}
            slotProps={{
              textField: {
                size: 'small',
                sx: { minWidth: 150 },
              },
            }}
          />
          <DatePicker
            label="Fecha fin"
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue);
              if (startDate || newValue) handleDateChange();
            }}
            slotProps={{
              textField: {
                size: 'small',
                sx: { minWidth: 150 },
              },
            }}
          />
          <TextField
            variant="outlined"
            placeholder="Buscar..."
            size="small"
            value={searchTerm}
            onChange={handleSearch}
            onKeyPress={handleSearchSubmit}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>

      {/* Tabla de pedidos */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código SAP</TableCell>
              <TableCell>Dirección</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Fecha entrega</TableCell>
              <TableCell align="right">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(() => {
              if (loading) {
                return (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress size={24} />
                    </TableCell>
                  </TableRow>
                );
              }

              if (orders.length > 0) {
                return orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.sapId || '-'}</TableCell>
                    <TableCell>{order.address || '-'}</TableCell>
                    <TableCell>{order.status || '-'}</TableCell>
                    <TableCell>{order.deliveryDate || '-'}</TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => {
                          window.location.href = `/vendedor/${sellerId}/pedidos/${order.id}/detalle`;
                        }}
                      >
                        Ver más
                      </Button>
                    </TableCell>
                  </TableRow>
                ));
              }

              return (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No hay pedidos disponibles
                  </TableCell>
                </TableRow>
              );
            })()}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Filas por página:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        />
      </TableContainer>
    </Box>
  );
};

OrderSummaryCard.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
};

Orders.propTypes = {
  clientId: PropTypes.string,
};

export default Orders;
