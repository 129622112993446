import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import thousandSeparator from '../../../utils/thousandSeparator';
import { getClientDailySales } from '../../../requests/api/client';

const Dashboard = ({ client }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [direction, setDirection] = useState('');
  const [salesData, setSalesData] = useState({});
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Función para procesar los datos de ventas según el rango de fechas y dirección
  const processSalesData = async () => {
    if (!client.id || !client.orders || !startDate || !endDate) return;

    setLoading(true);
    try {
      const data = await getClientDailySales(client.id, startDate, endDate, direction);

      // Agrupar ventas por día
      const salesByDay = {};

      data.orders.forEach((order) => {
        // Usar la fecha de la orden (date) para agrupar
        const orderDate = new Date(order.date);
        const dayKey = orderDate.toISOString().split('T')[0]; // Formato YYYY-MM-DD

        if (!salesByDay[dayKey]) {
          salesByDay[dayKey] = 0;
        }
        // Sumar el netValue de todas las facturas de la orden
        if (order.invoices && order.invoices.length > 0) {
          order.invoices.forEach((invoice) => {
            // Asegurarse de que netValue sea un número
            const netValue = Number(invoice.netValue) || 0;
            salesByDay[dayKey] += netValue;
          });
        }
      });

      // Ordenar los días cronológicamente
      const sortedDays = Object.keys(salesByDay).sort();

      // Crear datos para el gráfico
      if (sortedDays.length > 0) {
        const dataValues = sortedDays.map((day) => salesByDay[day]);

        setSalesData({
          labels: sortedDays,
          datasets: [
            {
              label: 'Ventas',
              data: dataValues,
              borderColor: '#62A606',
              backgroundColor: 'transparent',
              fill: false,
              tension: 0,
              pointBackgroundColor: '#62A606',
              pointBorderColor: '#62A606',
              pointRadius: 4,
              pointHoverRadius: 6,
            },
          ],
        });
      } else {
        // Si no hay datos, mostrar un gráfico vacío
        setSalesData({
          labels: [],
          datasets: [
            {
              label: 'Ventas',
              data: [],
              borderColor: '#62A606',
              backgroundColor: 'transparent',
              fill: false,
            },
          ],
        });
      }
    } catch (error) {
      enqueueSnackbar('Error al procesar los datos de ventas', { variant: 'error' });

      // En caso de error, mostrar un gráfico vacío
      setSalesData({
        labels: [],
        datasets: [
          {
            label: 'Ventas',
            data: [],
            borderColor: '#62A606',
            backgroundColor: 'transparent',
            fill: false,
          },
        ],
      });
    } finally {
      setLoading(false);
    }
  };

  // Efecto para cargar datos iniciales
  useEffect(() => {
    if (client.id) {
      // Inicializar fechas por defecto (último mes)
      const end = new Date();
      const start = new Date();
      start.setMonth(start.getMonth() - 1);

      setEndDate(end);
      setStartDate(start);
    }
  }, [client.id]);

  // Efecto para recargar datos cuando cambien los filtros
  useEffect(() => {
    if (client.id && startDate && endDate) {
      processSalesData();
    }
  }, [startDate, endDate, direction, client.orders]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => thousandSeparator(value, '.'),
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `Ventas: ${thousandSeparator(context.raw, '.')}`,
        },
      },
    },
  };

  return (
    <Box sx={{ pt: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Evolución Ventas
              </Typography>
              <Box sx={{
                mt: 2, display: 'flex', gap: 2, flexWrap: 'wrap',
              }}>
                <DatePicker
                  label="Fecha inicio"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: { minWidth: 180 },
                    },
                  }}
                />
                <DatePicker
                  label="Fecha fin"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: { minWidth: 180 },
                    },
                  }}
                />
                <FormControl size="small" sx={{ minWidth: 180 }}>
                  <Select
                    value={direction}
                    onChange={(e) => setDirection(e.target.value)}
                    displayEmpty
                    placeholder="Dirección"
                  >
                    <MenuItem value="">
                      <em>Todas las direcciones</em>
                    </MenuItem>
                    {client.clientDirections && client.clientDirections.map((d) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.name} - {d.address}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ height: 300, mt: 2, position: 'relative' }}>
                {loading ? (
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Line data={salesData} options={chartOptions} />
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

Dashboard.propTypes = {
  client: PropTypes.object.isRequired,
};

export default Dashboard;
